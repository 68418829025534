
.net-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;

    .top-select {
        margin-top: 20px;
    }

    .link-input-box {
        display: flex;

        .el-input-number {
            width: 88px;
        }

        .el-link {
            margin-left: 17px;
        }
    }

    .link-box {
        .el-link + .el-link {
            &:before {
                display: none;
            }
        }
    }

    .goods-img-dialog {
        .upload-file-content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 445px;

            .big-img {
                width: 444px;
                height: 444px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .small-img {
                flex: 1;
                width: 1%;
                margin-left: 94px;

                img {
                    width: 80px;
                    height: 80px;
                    margin-bottom: 15px;
                    border: 2px solid transparent;
                    box-sizing: border-box;

                    &:nth-child(2n-1) {
                        margin-right: 18px;
                    }

                    &:hover {
                        cursor: pointer;
                        border: 2px solid #2DC079;
                    }
                }
            }
        }
    }
}
